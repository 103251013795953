<template>
    <div class="row m-0" style="background-color: #2c6759; height: 120px;">

        <div class="col-12 flex-center p-0">

            <div class="flex-center" style="height: 120px; width: 120px;">
                <img :src="img_logo" alt="" class="img-fluid">
            </div>
            
            <div class="row w-100 m-0">
                <div class="col-12" style="height: 60px;">
                    
                </div>

                <div class="col-12 text-white bg-success d-flex" 
                    style="height: 60px; border-top-left-radius: 50px;">

                    <ul class="list-unstyled d-none d-md-flex justify-content-evenly w-100" 
                        style="padding-top: 18px">
                    
                        <li class="description-xs" @mouseenter="talk($event)" @mouseleave="stopTalking">
                            Inicio
                        </li>
                        <li class="description-xs" @mouseenter="talk($event)" @mouseleave="stopTalking">
                            Conoce al IMSS
                        </li>
                        <li class="description-xs" @mouseenter="talk($event)" @mouseleave="stopTalking">
                            IMSS Transparente
                        </li>
                        <li class="description-xs" @mouseenter="talk($event)" @mouseleave="stopTalking">
                            Directorio
                        </li>
                        <li class="description-xs" @mouseenter="talk($event)" @mouseleave="stopTalking">
                            Contacto ciudadano
                        </li>
                    </ul>

                    <div class="div-actions d-none d-md-block">
                        <div class="d-flex div-actions-content">
                            <ws-input placeholder="Buscar..." class="search_input_h me-2"/>

                            <div class="btn-pill mx-3"
                                style="height: 34px; width: 2px; background: white; opacity: 0.5; margin-top: 2px;">
                            </div>

                            <button class="btn btn-pill py-1 px-4 ms-2"
                                style="background: white;" type="button">
                                    Inicia Sesión
                            </button>
                        </div>
                    </div>

                    <div class="d-flex d-md-none w-100 flex-end-center">
                        <img :src="icon_menu" 
                            @click="openSidebar()"
                            class="cursor-pointer"
                            height="48">
                    </div>

                </div>
            </div>

        </div>


        <!-- Sidebar -->
        <div :class="`sidebar bg-success ${show_sidebar ? 'sidebar-active' : ''}`">

            <div class="position-relative w-100" style="height: 10px;">
                <div class="position-absolute" 
                    @click="closeSidebar()" 
                    style="left: 0; top: -30px">
                        <img :src="icon_close" 
                            class="cursor-pointer"
                            height="36">
                </div>    
            </div>

            <div class="w-100 flex-center mb-4" style="height: 120px; width: 120px;">
                <img :src="img_logo" alt="logo" class="img-fluid">
            </div>

            <div class="sidebar-item" @mouseenter="talk($event)" @mouseleave="stopTalking">
                <p class="m-0">
                    <a href="#" class="text-decoration-none text-white">
                        <strong>Inicio</strong>
                    </a>
                </p>
            </div>
            <div class="sidebar-item" @mouseenter="talk($event)" @mouseleave="stopTalking">
                <p class="m-0">
                    <a href="#" class="text-decoration-none text-white">
                        <strong>Conoce al IMSS</strong>
                    </a>
                </p>
            </div>
            <div class="sidebar-item" @mouseenter="talk($event)" @mouseleave="stopTalking">
                <p class="m-0">
                    <a href="#" class="text-decoration-none text-white">
                        <strong>IMSS Transparente</strong>
                    </a>
                </p>
            </div>
            <div class="sidebar-item" @mouseenter="talk($event)" @mouseleave="stopTalking">
                <p class="m-0">
                    <a href="#" class="text-decoration-none text-white">
                        <strong>Directorio</strong>
                    </a>
                </p>
            </div>
            <div class="sidebar-item" @mouseenter="talk($event)" @mouseleave="stopTalking">
                <p class="m-0">
                    <a href="#" class="text-decoration-none text-white">
                        <strong>Contacto ciudadano</strong>
                    </a>
                </p>
            </div>
        </div>

    </div>    
</template>

<script setup>
    // Images
    const img_logo = require('ASSETS/image/logo_white')
    const icon_close = require('ASSETS/icons/close')
    const icon_menu = require('ASSETS/icons/menu')

    // Imports
    import { ref } from 'vue'
    
    // Composables
    import { talk, stopTalking } from 'COMPOSABLES/speechSynthesis'

    // Variables
    const show_sidebar = ref(false)

    // Methods
    const openSidebar = () => {
        show_sidebar.value = true
    }
    const closeSidebar = () => {
        show_sidebar.value = false
    }
</script>

<style lang="scss" scoped>
    @import './../assets/scss/minxin.scss';

    .div-actions {
        //border: 1px solid red;
        position: absolute;
        max-height: 38px;
        width: 440px;
        right: 10px;

        @include upPixels(1200px) {
            position: relative;
            width: 740px;
        }

        .div-actions-content {
            position: absolute;
            top: -48px;

            @include upPixels(1200px) {
                position: absolute;
                top: -19px;
                rigth: -680px;
            }
        }
    }


    // Sidebar

    .sidebar {
        position: fixed; 
        top: 0px; 
        right: -280px; 
        z-index: 9990; 
        transition: all .5s; 

        height: 100%; 
        width: 280px; 
        
        background: rgb(19,50,43);
        background: linear-gradient(180deg, rgba(19,50,43,1) 14%, rgba(44,103,89,1) 100%);

        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
        
        padding-top: 40px;
        padding-bottom: 40px;

        &-item {
            color: white;
            width: 100%; 
            min-height: 50px;
            padding: 10px 0px;
            border-bottom: 2px solid gray;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }
    .sidebar-active {
        right: 0px !important;
    }
</style>



